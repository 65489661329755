<template>
  <v-container grid-list-xl fluid>
    <v-layout row wrap>
      <v-flex lg12>
        <v-card>
          <hpo-filter
            :headers="headers"
            :viewName="viewName"
            v-on:applyFilter="applyFilter"
            v-on:quickFilter="quickFilter"
          ></hpo-filter>
          <v-data-table fixed-header height="750px"  
            :headers="headers"
            :items="items"
            :options.sync="pagination"
            :server-items-length="total_rows"
            :footer-props="items_per_page"
            :must-sort="true"
            :loading="loading"
            :mobile-breakpoint="100"
          >
            <template v-slot:item="{ item }">
              <tr :id="'r-'+item.ID">
                <td>{{ item.PackageCode }}</td>
                <!-- <td>
                  <v-edit-dialog large lazy persistent @save="saveItem({ID: item.ID, ProductType: item.ProductType})">
                      <div>
                          {{ item.ProductType | display_value(product_types) }}
                      </div>
                      <template v-slot:input>
                          <v-autocomplete clearable class="input-group--focused" label="Loại hàng" v-model="item.ProductType" :items="product_types" item-text="name" item-value="value"></v-autocomplete>
                      </template>
                  </v-edit-dialog>
                </td> -->
                <td class="text-right">{{ item.Weight }}</td>
                <td class="text-right">{{ item.RealWeight }}</td>
                <td class="text-right font-weight-bold">{{ item.ReceivedWeight }}</td>
                <td class="text-right">{{ item.Volume }}</td>
                <td class="text-right font-weight-bold">{{ item.ReceivedPacks }}</td>
                <td class="text-right">{{ item.Amount | currency }}</td>
                <td>{{ item.ExDate | display_date }}</td>
                <td>{{ item.ImDate | display_date }}</td>
                <td>{{ item.ModifiedBy }}</td>
                <td class="text-center">
                  <v-icon medium class="mr-2" @click="gotoDetail(item.ID)">fa-external-link-alt</v-icon>
                </td>
              </tr>
            </template>
            <template v-slot:body.append v-if="summary">
                <tr class="table-summary">
                    <td class="font-weight-bold">TỔNG</td>
                    <td class="font-weight-bold text-end">{{ summary.Weight | currency}}</td>
                    <td class="font-weight-bold text-end">{{ summary.RealWeight | currency}}</td>
                    <td class="font-weight-bold text-end">{{ summary.ReceivedWeight | currency}}</td>
                    <td class="font-weight-bold text-end">{{ summary.Volume | currency}}</td>
                    <td class="font-weight-bold text-end">{{ summary.ReceivedPacks | currency}}</td>
                    <td class="font-weight-bold text-end">{{ summary.Amount | currency}}</td>
                    <td class="font-weight-bold text-end"></td>
                    <td class="font-weight-bold text-end"></td>
                    <td class="font-weight-bold text-end"></td>
                    <td class="font-weight-bold text-end"></td>
                </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialogBag" max-width="300px" :persistent="isMobile" :fullscreen="isMobile" :hide-overlay="isMobile" transition="dialog-bottom-transition">
        <v-card>
            <v-toolbar dark color="oee-theme" v-if="isMobile">
                <v-btn icon dark @click="dialogBag = false">
                <v-icon>fa-times-circle</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-toolbar-title>Mã bao hàng</v-toolbar-title>
            </v-toolbar>
            <v-card-title v-else>
              <span class="title">Mã bao hàng</span>
            </v-card-title>
            <v-card-text>
              <v-container grid-list-xl fluid>
                  <v-layout row wrap>
                      <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"  v-if="dialogBag && isMobile && newBigpackageCode == ''"></StreamBarcodeReader>              
                      <v-flex lg12 md12 sm12 xs12 pa-0>
                          <v-text-field class="input-group--focused" ref="popup_trancode" autofocus label="Mã bao hàng" v-model="newBigpackageCode" @keyup.enter="focusNext"></v-text-field>
                      </v-flex>
                      <v-flex lg12 md12 sm12 xs12 pa-0>
                          <h-currency-input class="input-group--focused" v-model="newBigpackageWeight" label="Cân nặng" :decimal="1" @press_enter="saveNewBigpackageCode"></h-currency-input>
                      </v-flex>
                  </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1 white--text" class="mt-8" v-if="!isMobile" @click="dialogBag = false">Đóng</v-btn>
              <v-btn color="red darken-1 white--text"  class="mt-8" :block="isMobile" @click="saveNewBigpackageCode">OK</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import configs from "../../configs";
import Filtering from "@/components/Filtering";
import moment from "moment"
import { getCurrentTime, sendErrorNotice, isMobileAgent, focusNextElement } from "@/commons/utils";
import _ from "lodash";

export default {
  components: {
    "hpo-filter": Filtering
  },
  data() {
    let dataType = configs.DATA_TYPE;
    return {
      headers: [
        {
          text: "Bao hàng",
          value: "PackageCode",
          filterable: true,
          quickSearch: true,
          dataType: dataType["String"]
        },
        // {
        //   text: "Loại hàng",
        //   value: "ProductType",
        //   filterable: true,
        //   quickSearch: true,
        //   values: [],
        //   dataType: dataType["Enum"]
        // },
        {
          text: "Cân nặng (TQ)",
          value: "Weight",
          align: "end",
          filterable: true,
          dataType: dataType["Number"]
        },
        {
          text: "Cân thực tế",
          value: "RealWeight",
          align: "end",
          filterable: true,
          dataType: dataType["Number"]
        },
        {
          text: "Cân nặng đã nhập",
          value: "ReceivedWeight",
          align: "end",
          filterable: true,
          dataType: dataType["Number"]
        },
        {
          text: "Số kiện (TQ)",
          value: "Volume",
          align: "end",
          filterable: true,
          dataType: dataType["Number"]
        },
        {
          text: "Số kiện đã nhập",
          value: "ReceivedPacks",
          align: "end",
          filterable: true,
          dataType: dataType["Number"]
        },
        {
          text: "Phí ship",
          value: "Amount",
          align: "end",
          filterable: true,
          dataType: dataType["Number"]
        },
        {
          text: "Ngày xuất",
          value: "ExDate",
          filterable: true,
          dataType: dataType["Date"]
        },
        {
          text: "Ngày nhập",
          value: "ImDate",
          filterable: true,
          dataType: dataType["Date"]
        },
        {
          text: "Người nhập",
          value: "CreatedBy",
          filterable: true,
          dataType: dataType["String"]
        },
        {
          text: "Thao tác",
          value: "",
          align: "center",
          sortable: false,
          filterable: false
        }
      ],
      pagination: {  ...configs.PAGINATION,  sortBy: ["ID"]  },
      items_per_page: _.cloneDeep(configs.ITEMS_PER_PAGE),
      viewName: "bigpackagevn_list",
      filterConditions: [],
      quickFilterConditions: [],
      requireRules: [v => !!v || "Trường dữ liệu không được để trống."],
      dialogPackageDetail: false,
      dialogBag: false,
      currentTime: getCurrentTime(),
      newBigpackageCode: '', 
      newBigpackageWeight: 0,
      focusNext: null,
    };
  },
  watch: {
    pagination: {
      handler: function(val, oldVal) {
        let filterConditions = this.$store.state.commons.filterConditions;
        this.filterConditions = !_.isEmpty(filterConditions)
          ? filterConditions[this.viewName]
          : [];
        this.filter_data();
      },
      deep: true
    },
    product_types() {
      let header_item = _.find(this.headers, { value: "ProductType" });
      if (header_item) {
          header_item.values = this.product_types;
      }
    },
  },
  computed: {
    ...mapState({
      items: state => state.bigPackage.all.data,
      total_rows: state => state.bigPackage.all.total,
      summary: state => state.bigPackage.all.summary,
      currentItem: state => state.bigPackage.selected,
      notTransit: state => state.bigPackage.notTransit,
      loading: state => state.bigPackage.loading,
      configuration: state => state.configuration.selected,
    }),
    param() {
      let pr = {
        pagination: this.pagination,
        filter: this.filterConditions,
        quickFilter: this.quickFilterConditions
      };
      pr.filter.push({
        column: "Status",
        value: configs.BIG_PACKAGE_STATUS_ENUM.NHAP_KHO_VN,
        condition: "equal"
      });
      return pr;
    },
    product_types() {
      return this.configuration.ProductTypes ? JSON.parse(this.configuration.ProductTypes) : [];
    },
    isMobile: isMobileAgent
  },
  methods: {
    filter_data: function() {
      this.$store.dispatch("bigPackage/getList", this.param);
    },
    gotoDetail: function (id) {
      this.$store.commit("bigPackage/setDetail", {
        data: {
          ID: 0,
          CreatedDate: new Date(),
          PackageCode: '',
          Weight: 0,
          Volume: 0,
          Amount: 0
        }
      });
      this.$router.push({
        name: "StoreManagerVNDetail",
        params: {
        Pid: id,
        title: 'Chi tiết nhập kho'
        }
      });
    },
    deleteItem: function(id) {
      window.getApp.$emit("REQUEST_DELETE_DATA", id);
    },    
    applyFilter(filterConditions) {
      let sortBy = this.pagination.sortBy;
      let sortDesc = this.pagination.sortDesc;
      this.pagination = configs.PAGINATION;
      this.pagination.sortBy = sortBy;
      this.pagination.sortDesc = sortDesc;
      this.filterConditions = filterConditions;
      this.filter_data();
    },
    quickFilter(filterConditions) {
      this.quickFilterConditions = filterConditions;
      this.filter_data();
    },
    createData(username = "") {
      this.$store.commit("bigPackage/setDetail", {
        data: {
          ID: 0,
          ImDate: new Date(),
          PackageCode: '',
          BigPackageID: -1,
          Weight: 0,
          Volume: 0,
          Amount: 0
        }
      });
      this.$router.push({
          name: "StoreManagerVNDetail",
          params: {
          Pid: 'nhap-bao-moi',
          title: 'Nhập bao mới'
          }
      });
    },
    saveItem(item) {
      this.$store.dispatch("bigPackage/setDetail", {
        data: item,
        id: item.ID
      });
    },
    saveData() {
      this.saveItem(this.currentItem);
      this.dialogPackageDetail = false;
    },
    closePopup() {
      this.dialogPackageDetail = false;
      this.filter_data();
    },
    getListPackageNotTran() {
      this.$store.dispatch("bigPackage/getListNotTransit");
    },
    importBag() {
      this.dialogBag = true;
    },
    saveNewBigpackageCode() {
      this.$store.dispatch("bigPackage/setDetail", {
        data: {
          ImportType: 'AllInBag',
          PackageCode: this.newBigpackageCode,
          ReceivedWeight: this.newBigpackageWeight,
          Status: configs.BIG_PACKAGE_STATUS_ENUM.NHAP_KHO_VN
        },
        id: 9999999999
      });
      this.newBigpackageCode = '';
      this.newBigpackageWeight = 0;
      this.$nextTick(() => this.$refs.popup_trancode.focus());
    }
  },
  created() {
    this.focusNext = focusNextElement;
  },
  mounted() {
    this.getListPackageNotTran();
    this.$store.dispatch("configuration/getDetail", 1);
    this.$root.$emit('REQUEST_ROOT_OPTION', {});
    this.$root.$on("REQUEST_OTHER_ACTION", this.importBag);
    this.$root.$on("REQUEST_CREATE_DATA", this.createData);
    this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
    this.$root.$on("CONFIRMED_REMOVE_DATA", id => {
      this.$store.dispatch("bigPackage/removeDetail", id);
      setTimeout(this.filter_data, 200);
    });
  },
  beforeDestroy() {
    this.$root.$off("REQUEST_OTHER_ACTION", this.importBag);
    this.$root.$off("REQUEST_CREATE_DATA", this.createData);
    this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
    this.$root.$off("CONFIRMED_REMOVE_DATA");
  }
};
</script>
